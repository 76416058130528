import styled from 'styled-components'
import Image from 'next/image'
import { Fragment } from 'react'
import {
    BrowserView, MobileView,
} from "react-device-detect";

const SectionWrapper = styled.section`
    width: 70%;
    min-height: 50vh;
    position: relative;
    margin: 50px auto;
`

const Stats = () => {
    return <Fragment>
        <SectionWrapper>
            <MobileView>
                <Image layout="fill" src={`/wonder-women/mobile/3-stats.png`} objectFit="contain" alt={'stats'} />
            </MobileView>
            <BrowserView>
                <Image layout="fill" src={`/wonder-women/3-stats.png`} objectFit="contain" alt={'stats'} />
            </BrowserView>
        </SectionWrapper>
    </Fragment>
}

export default Stats;
