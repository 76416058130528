import Head from 'next/head'
import styled from 'styled-components'
import Banner from '../components/Banner'
import ImageGrids from '../components/ImageGrids'
import GridTextRotator from '../components/GridTextRotater'
import Quotes from '../components/Quotes'
import Stats from '../components/Stats'
import Footer from '../components/Footer'
import Names from '../components/Names'

const PageWrapper = styled.section`
  overflow-x: hidden;
  width: 100%;
`

const SecondFold = styled.section`
  position: relative;
`

export default function Home() {
  return <PageWrapper>
    <Head>
      <title>#forHer | Wonder womens | pickyourtrail</title>
    </Head>

    <Banner />

    {/* Image Grid with masonry */}
    <SecondFold>
      <GridTextRotator />
      <ImageGrids />
    </SecondFold>

    <Quotes />

    <Stats />

    <Names />

    <Footer />
  </PageWrapper>
}
