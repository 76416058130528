import styled from 'styled-components'
import Image from 'next/image'
import { Fragment } from 'react'
import {
    BrowserView, MobileView,
} from "react-device-detect";

const SectionWrapper = styled.section`
    width: 100%;
    min-height: 420px;
    position: relative;
`

const Footer = () => {
    return <Fragment>
        <SectionWrapper>
            <MobileView>
                <Image layout="fill" src={`/wonder-women/mobile/4-footer.png`} objectFit="cover" alt={'footer'} />
            </MobileView>
            <BrowserView>
                <Image layout="fill" src={`/wonder-women/4-footer.png`} objectFit="cover" alt={'footer'} />
            </BrowserView>
        </SectionWrapper>
    </Fragment>
}

export default Footer;
