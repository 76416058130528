import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { sm } from '../utils/device'
// import ReactRotatingText from 'react-rotating-text'

const TextWrapper = styled.section`
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;

    position: absolute;
    z-index: 1;
    top: 50px;

    ${sm} {
        top: 120px;
    }
`

const Title = styled.h2`
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors?.black};
    text-align: center;
    
    strong {
        font-weight: 800;
        color: ${({ theme }) => theme.colors?.primary};
    }

    font-size: 28px;
    line-height: 34px;

    ${sm} {
        font-size: 48px;
        line-height: 58px;    
        margin-bottom: 8px;
    }
`

const SubTitle = styled.h3`
    font-weight: 600;
    letter-spacing: -0.01em;
    margin: 0;
    color: ${({ theme }) => theme.colors?.black};
    opacity: 0.3;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    ${sm} {
        font-size: 48px;
        line-height: 58px;
    }
`

const _DATA = [
    {
        title: "a mom",
        sub: 'curates vacations'
    },
    {
        title: "a daugher-in-law",
        sub: 'runs training for us'
    },
    {
        title: "a professional dancer",
        sub: 'chases reveue for us'
    },
    {
        title: "a daugher",
        sub: 'runs conceirge support for us'
    },
    {
        title: "an artist",
        sub: 'sells travel experience'
    },
]

const GridTextRotator = () => {
    const [active, setActive] = useState(0)

    const ChangeActiveText = () => {
        setTimeout(() => {
            let nextActiveIndex = active+1
            if(nextActiveIndex > (_DATA.length - 1)) {
                nextActiveIndex = 0
            }
    
            setActive(nextActiveIndex)
        }, 3200)
    }

    useEffect(() => {
        ChangeActiveText()
    },[active])

    return <TextWrapper>
        <Title>She's <strong>
                {_DATA[active]?.title}
                {/* <ReactRotatingText 
                    emptyPause={0}
                    pause={3000}
                    onTypingEnd={ChangeActiveText}
                    cursor={false} items={['a mom', 'a daugher-in-law', 'a professional dancer', 'a daugher', 'an artist']} /> */}
            </strong>
        </Title>
        <SubTitle>She also {_DATA[active]?.sub}</SubTitle>
    </TextWrapper>
}

export default GridTextRotator;
