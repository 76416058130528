import styled from 'styled-components'
import Marquee from "react-fast-marquee";
import { sm } from '../utils/device';
import { isMobile } from 'react-device-detect';

const SectionWrapper = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 56px;
    
    ${sm} {
        margin-bottom: 176px;
    }
`

const NameWrapper = styled.span`
    font-weight: 800;

    text-transform: lowercase;
    margin-bottom: 24px;
    margin-right: 24px;
    
    font-size: 32.1186px;
    line-height: 39px;

    ${sm} {
        font-size: 68px;
        line-height: 82px;
    }

    color: ${({ theme }) => theme.colors?.grey};
    
    strong {
        font-weight: 800;
        color: ${({ theme }) => theme.colors?.black};
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
`

const _NAMES = [
    [
        {name: 'Lubna', slackName: 'olive'},
        {name: 'Shruthi', slackName: 'Pheonix'},
        {name: 'Dwaraka', slackName: 'Parvathi'},
        {name: 'Samantha', slackName: 'Groot'},
        {name: 'Divya', slackName: 'dj'},
        {name: 'Varshaa', slackName: 'Shuri'},
    ],
    [
        {name: 'Meenatchi', slackName: 'Bumblebee'},
        {name: 'Meekha', slackName: 'Minion'},
        {name: 'SHARMILA', slackName: 'Rapunzel'},
        {name: 'Harinee', slackName: 'Pixie'},
        {name: 'Priyanka', slackName: 'Strom'},
        {name: 'Khushboo', slackName: 'Rachelgreen'},
    ],
    [
        {name: 'KAJANI', slackName: 'Laracroft'},
        {name: 'Rajoo', slackName: 'Santiago'},
        {name: 'Vibha', slackName: 'Catwoman'},
        {name: 'SHAMANTHIKA', slackName: 'Dory'},
        {name: 'Preetha', slackName: 'theredwitch'},
    ],
    [
        {name: 'Priyadharshini', slackName: 'moana'},
        {name: 'Swathi', slackName: 'superwoman'},
        {name: 'Keerthi', slackName: 'Shinchan'},
        {name: 'Malavika', slackName: 'zatanna'},
        {name: 'Deepika', slackName: 'mia'},
    ],
    [
        {name: 'PARIDHI', slackName: 'mokkaimaami'},
        {name: 'Sowmya', slackName: 'kungfuponda'},
        {name: 'Anitha', slackName: 'olaf'},
        {name: 'Rashmi', slackName: 'zoozoo'},
        {name: 'Keerthana', slackName: 'tecna'},
    ],
    [
        {name: 'Titikshaa', slackName: 'pickachu'},
        {name: 'Suman', slackName: 'jerry'},
        {name: 'Lavanya', slackName: 'blossom'},
        {name: 'Mithra', slackName: 'shazam'},
        {name: 'Shajana', slackName: 'chaos'},
    ]
]

const Names = () => {
    return <SectionWrapper>
        {
            _NAMES.map((row, rowIndex) => {
                return <Marquee gradient={false} speed={isMobile ? 15 : 50}>
                    <RowWrapper key={`row-${rowIndex}`}>
                        {
                            row.map((item, index) => {
                                return <NameWrapper key={`name-${index}`}><strong>{item.name}</strong>{item.slackName}</NameWrapper>
                            })
                        }
                    </RowWrapper>
                </Marquee>
            })
        }
    </SectionWrapper>
}

export default Names;
