import NextImage from "next/image"
import { useEffect, useRef, useState } from "react"
import styled from 'styled-components';

const ImageWrapper = styled.div`
    img {
        transition: 700ms all ease-in-out;
        opacity: 1;
        filter: blur(${({loading}) => loading ? '5px' : '0px'});
    }
`;

const ProgressiveImage = ({
    src,
    preview,
    ...rest
}) => {
    const [loading, setLoading] = useState(true)
    const [currentImage, setCurrentImage] = useState(preview)
    const loadingImage = useRef()

    useEffect(() => {
        fetchImage()

        return () => {
            loadingImage.current = null;
        }
    },[])

    const fetchImage = () => {
        const tmpImage = new Image()
        if(tmpImage) {
            tmpImage.onload = () => {
                setCurrentImage(loadingImage?.current?.src)
                setLoading(false)
            }
            tmpImage.src = src
            loadingImage.current = tmpImage
        }
    }

    return <ImageWrapper loading={loading}>
        <NextImage src={currentImage} {...rest} />
    </ImageWrapper> 
}

export default ProgressiveImage;
