import styled from 'styled-components'
import ReactPlayer from 'react-player'
import Image from 'next/image'
import { sm } from '../utils/device'
import { isMobile } from 'react-device-detect'

const BannerWrapper = styled.section`
    width: 100%;
    height: 100vh;
`

const BannerContent = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.57);

    display: flex;
    align-items: center;
    justify-content: center;
`

const Title = styled.h1`
    font-weight: 800;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.colors.white};

    font-size: 56px;
    line-height: 68px;

    ${sm} {
        font-size: 88px;
        line-height: 106px;    
    }
`

const Logo = styled.div`
    width: 100%;
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
`

const _DATA = {
    videoLink: 'https://pyt-images.imgix.net/images/wonder-women/mobile/wd-video-mobile.mp4?auto=format',
    desktopVideoLink: 'https://pyt-images.imgix.net/images/wonder-women/wd-video-desktop.mp4?auto=format'
}

const Banner = () => {
    return <BannerWrapper>
        <ReactPlayer
            url={[{ src: isMobile ? _DATA.videoLink : _DATA.desktopVideoLink, type: 'video/mp4' }]}
            loop={true}
            width="100%"
            height="100%"
            playing={true}
            muted
        />
        <Logo>
            <Image src="/web_app/logo/pickyourtrail-logo-white.svg" alt="pickyourtrail-logo" width={160} height={28} />
        </Logo>
        <BannerContent>
            <Title>#forHer</Title>
        </BannerContent>
    </BannerWrapper>
}

export default Banner;
