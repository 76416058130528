import styled from 'styled-components'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import shuffleArray from '../utils/shuffleArray'
import { sm } from '../utils/device'
import Marquee from "react-fast-marquee";
import { isMobile } from 'react-device-detect'
import ProgressiveImage from './ProgressiveImage'

const GridSectionWrapper = styled.section`
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white };
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 90px;
`

const ImageCol = styled.div`
    display: flex;
    flex-direction: column;

    :nth-of-type(1) {
        margin-top: 200px;
    }
    
    :nth-of-type(2) {
        margin-top: 230px;
    }

    :nth-of-type(3) {
        margin-top: 260px;
    }
    
    :nth-of-type(4) {
        margin-top: 290px;
    }

    :nth-of-type(5) {
        margin-top: 260px;
    }

    :nth-of-type(6) {
        margin-top: 230px;
    }

    :nth-of-type(7) {
        margin-top: 210px;
    }

    ${sm} {
        :nth-of-type(1) {
            margin-left: -25px;
            margin-top: 250px;
        }
        
        :nth-of-type(2) {
            margin-top: 360px;
        }
    
        :nth-of-type(3) {
            margin-top: 410px;
        }
        
        :nth-of-type(4) {
            margin-top: 520px;
        }
    
        :nth-of-type(5) {
            margin-top: 410px;
        }
    
        :nth-of-type(6) {
            margin-top: 360px;
        }
    
        :nth-of-type(7) {
            margin-top: 270px;
        }
    }

`

const ImageContainer = styled.div`
    margin: 0 16px 16px 0;
    position: relative;

    width: 92.5px;
    height: 115.62px;

    ${sm} {
        width: 200px;
        height: 250px;
    }
`

const ImageGrids = () => {
    const [activeImages, setActiveImages] = useState({});

    const getRandomImages = () => {
        const tempArr = Array.from({length: 42}, (_, i) => i + 1)
        shuffleArray(tempArr)

        const imageIndexes = tempArr.slice(0, 17)
        const cols = {
            col1: imageIndexes.slice(0, 3),
            col2: imageIndexes.slice(3, 5),
            col3: imageIndexes.slice(5, 7),
            col4: imageIndexes.slice(7, 9),
            col5: imageIndexes.slice(9, 11),
            col6: imageIndexes.slice(11, 14),
            col7: imageIndexes.slice(14, 17),
        }

        setActiveImages(cols)
    }

    useEffect(() => {
        // TO GET FIRST SET OF IMAGES
        getRandomImages()

        setInterval(() => {
            getRandomImages()
        }, 5000)
    }, [])

    const defaultFactors = '?fit=crop&auto=format';
    const previeeImgFactors = isMobile ? 'w=9&h=11' : 'w=20&h=25'

    return <GridSectionWrapper>
            <Marquee gradient={false} speed={isMobile ? 10 : 0}>
                {Object.keys(activeImages).map(item => {
                    const currentCol = activeImages[item];

                    return <ImageCol>
                            {currentCol.map(img => {
                                const imgName = `pic000${img > 9 ? img : '0'+img}`;
                                return <ImageContainer key={`image-${img}`} >
                                    <ProgressiveImage
                                        width={isMobile ? 93 : 200}
                                        height={isMobile ? 116 : 250}
                                        unoptimized={true}
                                        preview={`https://pyt-images.imgix.net/images/wonder-women/womens/${imgName}.png${defaultFactors}&${previeeImgFactors}`}
                                        src={`https://pyt-images.imgix.net/images/wonder-women/womens/${imgName}.png${defaultFactors}`} objectFit="cover" alt={imgName} />
                                </ImageContainer>
                            })}
                        </ImageCol>
                })}
        </Marquee>
    </GridSectionWrapper>
}

export default ImageGrids;
