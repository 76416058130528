import styled from 'styled-components'
import { Fragment } from 'react'
import {
    BrowserView, isMobile, MobileView,
} from "react-device-detect";
import { sm } from '../utils/device';
import ProgressiveImage from './ProgressiveImage';

const SectionWrapper = styled.section`
    width: 100%;
    min-height: 870px;
    position: relative;
    
    :nth-of-type(3) {
        min-height: 900px;
    }
    
    ${sm} {
        height: 100vh;
    } 
`

const defaultFactors = 'fit=crop&auto=format';
const previeeImgFactors = isMobile ? 'w=40&h=80' : 'w=100&h=55'

const _DATA = [
    {
        image: `https://pyt-images.imgix.net/images/wonder-women/mobile/1-srinath.png?${defaultFactors}`,
        desktopImage: `https://pyt-images.imgix.net/images/wonder-women/1-srinath.png?${defaultFactors}`,
        alt: 'srinath-quote'
    },
    {
        image: `https://pyt-images.imgix.net/images/wonder-women/mobile/2-hari.png?${defaultFactors}`,
        desktopImage: `https://pyt-images.imgix.net/images/wonder-women/2-hari.png?${defaultFactors}`,
        alt: 'hari-quote'
    },
    {
        image: `https://pyt-images.imgix.net/images/wonder-women/mobile/ajit-mobile.png?${defaultFactors}`,
        desktopImage: `https://pyt-images.imgix.net/images/wonder-women/ajit.png?${defaultFactors}`,
        alt: 'ajit-quote'
    }
]
const Quotes = () => {
    return <Fragment>
        <MobileView>
            {_DATA.map((item, index) => {
                return <SectionWrapper key={`mobile-id-${index}`}>
                    <ProgressiveImage 
                        unoptimized={true}
                        preview={`${item.image}&${previeeImgFactors}`}
                        layout="fill" src={item.image} objectFit="cover" alt={`${item.alt}-mobile`} />
                </SectionWrapper>
            })}
        </MobileView>

        <BrowserView>
            {_DATA.map((item, index) => {
                return <SectionWrapper key={`desktop-id-${index}`}>
                    <ProgressiveImage 
                        unoptimized={true}
                        preview={`${item.desktopImage}&${previeeImgFactors}`}
                        layout="fill" src={item.desktopImage} objectFit="cover" alt={item.alt} />
                </SectionWrapper>
            })}
        </BrowserView>
    </Fragment>
}


export default Quotes;
