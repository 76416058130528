const size = {
    xys: '670px',
    xxs: '376px',
    xs: '767px', // 767px & below is mobile
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xlg: '1440px'
};
  
// xys will check both height and width - mainly to serve different styles targeted to iphone upto v6
export const xys = () =>
`@media (max-width: ${size.xxs}) and (max-height: ${size.xys})`;

export const xxs = () => `@media (max-width: ${size.xxs})`;
export const xs = () => `@media (max-width: ${size.xs})`;
export const sm = () => `@media (min-width: ${size.sm})`;
export const md = () => `@media (min-width: ${size.md})`;
export const lg = () => `@media (min-width: ${size.lg})`;
export const xlg = () => `@media (min-width: ${size.xlg})`;
